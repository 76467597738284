import React from 'react';

import Bot from './Bot';
import useApp from './hooks/useApp';
import CustomLoader from './components/CustomLoader';
import ErrorDisplay from './components/ErrorDisplay';

import { ThemeProvider } from '@emotion/react';

const App: React.FC = () => {
  const {
    theme,
    prompt,
    appData,
    responses,
    isLoading,
    showPopup,
    setPrompt,
    botDetails,
    handleSubmit,
    errorMessage,
    currentStream,
    isStreamLoading,
    isWidgetLoading,
    handleClosePopup,
  } : any= useApp();

  return (
    
    isWidgetLoading ?
      <CustomLoader mode={appData?.botMode} /> :
      !isWidgetLoading && errorMessage ?
      <ErrorDisplay errorMessage={errorMessage} />
         :
        botDetails ?
          <ThemeProvider theme={theme ? theme : ''}>
            <Bot
              botDetails={botDetails}
              showPopup={showPopup}
              handleClose={handleClosePopup}
              isLoading={isLoading}
              prompt={prompt}
              responses={responses}
              currentStream={currentStream}
              setPrompt={setPrompt}
              handleSubmit={handleSubmit}
              isStreamLoading={isStreamLoading}
              theme={theme}
            />
          </ThemeProvider> : null
  );
}

export default App;
