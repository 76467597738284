import { useCallback, useEffect, useState } from "react";

import { botEngineConfig } from "../config";
import { WidgetDetails, BotDetails, Response } from "../types/common-types";

import axios from "axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";

const useApp = () => {
  const fullUrl = window.location.href;

  const { baseUrl } = botEngineConfig();

  const storedShowPopup = localStorage.getItem("showPopup");

  const [theme, setTheme] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [referrer, setReferrer] = useState("");
  const [queryHash, setQueryHash] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentStream, setCurrentStream] = useState("");
  const [responses, setResponses] = useState<Response[]>(() => {
    const savedResponses = localStorage.getItem(fullUrl);
    return savedResponses ? JSON.parse(savedResponses) : [];
  });
  const [isWidgetLoading, setIsWidgetLoading] = useState(true);
  const [isStreamLoading, setStreamIsLoading] = useState(false);
  const [appData, setAppData] = useState<WidgetDetails | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [botDetails, setBotDetails] = useState<BotDetails | null>(null);
  const [latestMessage, setLatestMessage] = useState({
    message: "",
    isUser: false,
  });
  const [showPopup, setShowPopup] = useState(
    storedShowPopup === "true" || storedShowPopup === null
  );

  const handleSubmit = useCallback(() => {
    if (prompt.trim() === "") return;
    setIsLoading(true);
    setStreamIsLoading(true);
    setPrompt("");
    const newUserMessage = { message: prompt, isUser: true };
    responses.push(newUserMessage);
    localStorage.setItem(fullUrl, JSON.stringify(responses));
    setResponses(responses);
    const body = {
      vectorIndexId: appData?.vectorIndex,
      policyId: botDetails?.policyId,
      botId: appData?.botId,
      parentUrl: referrer,
      history: responses,
    };
    fetchEventSource(`${baseUrl}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        userID: appData?.userId || "",
      },
      body: JSON.stringify(body),
      onmessage(event: any) {
        const data = event.data;

        if (event.event === "error") {
          setErrorMessage(event.data);
          setIsLoading(false);
          setStreamIsLoading(false);
        } else if (data === "[DONE]") {
          const aMessage = { message: latestMessage.message, isUser: false };
          responses.push(aMessage);
          setResponses(responses);
          localStorage.setItem(fullUrl, JSON.stringify(responses));
          latestMessage.message = "";
          setCurrentStream("");
          setStreamIsLoading(false);
        } else {
          latestMessage.message = latestMessage.message + data;
          setLatestMessage(latestMessage);
          setCurrentStream((prevStream) => prevStream + data);
          if (data) setIsLoading(false);
        }
      },
      onclose() {
        console.log("Connection closed by the server");
        setIsLoading(false);
        setStreamIsLoading(false);
      },
      onerror(err) {
        console.error("Error occurred:", err);
        setIsLoading(false);
        setStreamIsLoading(false);
      },
    });
  }, [prompt, responses, currentStream]);

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("showPopup", "false");
  };

  const renderWidget = async ({
    botId,
    botThemeId,
    parentUrl,
  }: {
    botId: string;
    botThemeId: string;
    parentUrl: string;
  }) => {
    const requestBody = {
      botId,
      botThemeId,
      parentUrl,
    };
    try {
      const response = await axios.post(
        `${baseUrl}/widget/render`,
        requestBody
      );
      setBotDetails(response.data);
      return response.data.mode;
    } catch (error: any) {
      console.error(error);
      setIsWidgetLoading(false);
      if (error.response?.status === 404) {
        setErrorMessage("Something went wrong.");
      } else {
        setErrorMessage(error.response?.data?.message);
      }
    }
  };

  const fetchData = async () => {
    const requestBody = {
      encryptedData: queryHash,
    };
    try {
      const response = await axios.post(
        `${baseUrl}/bots/bot-widget`,
        requestBody
      );
      setAppData(response.data);
      const mode = await renderWidget({
        botId: response.data.botId,
        botThemeId: response.data.botThemeId,
        parentUrl: referrer,
      });
      const { darkTheme, lightTheme } = await import(
        `../${response.data.botThemePath}`
      );
      await setTheme(mode === "dark" ? darkTheme : lightTheme);
      setIsWidgetLoading(false);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const decodedQueryHash = decodeURIComponent(queryParams.get("query") || "");
    setQueryHash(decodedQueryHash);
    setReferrer(document?.location?.ancestorOrigins[0]);
  }, []);

  useEffect(() => {
    if (queryHash) {
      fetchData();
    }
  }, [queryHash, referrer]);

  useEffect(() => {
    localStorage.setItem("showPopup", showPopup.toString());
  }, [showPopup]);

  useEffect(() => {
    localStorage.setItem(fullUrl, JSON.stringify(responses));
  }, [responses]);

  return {
    theme,
    prompt,
    appData,
    isLoading,
    responses,
    setPrompt,
    showPopup,
    botDetails,
    handleSubmit,
    errorMessage,
    setShowPopup,
    currentStream,
    isStreamLoading,
    isWidgetLoading,
    handleClosePopup,
  };
};

export default useApp;
