import { Box } from '@mui/material';
import { styled } from '@mui/system';



const CustomLoader = (props: any) => {
  const {mode} = props
  const LoaderWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: mode === 'dark' ? '#19191e' : '#fff',
  });
  
  const DotsWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div': {
      width: 28,
      height: 28,
      margin: 4,
      borderRadius: '50%',
      backgroundColor: '#0692E2',
      animation: 'dotBounce 1.4s infinite ease-in-out both',
    },
    '& div:nth-of-type(1)': {
      animationDelay: '-0.32s',
    },
    '& div:nth-of-type(2)': {
      animationDelay: '-0.16s',
    },
    '@keyframes dotBounce': {
      '0%, 80%, 100%': {
        transform: 'scale(0)',
      },
      '40%': {
        transform: 'scale(1)',
      },
    },
  });
  return(
  <LoaderWrapper>
    <DotsWrapper>
      <Box component="div" />
      <Box component="div" />
      <Box component="div" />
    </DotsWrapper>
  </LoaderWrapper>
)};

export default CustomLoader;
