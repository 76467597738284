import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ErrorDisplayProps {
  errorMessage: string
}
const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: 'white',
  color: '#29292e',
  padding: '16px',
  textAlign: 'center',
}));

const ErrorIconWrapper = styled(Box)(({ theme }) => ({
  marginBottom: '16px',
  color: '#0692E2',
  '& .MuiSvgIcon-root': {
    fontSize: '64px',
  },
}));
const ErrorDisplay = (props: ErrorDisplayProps) => {
  const { errorMessage } = props
  return (
    <Wrapper>
      <ErrorIconWrapper>
        <ErrorOutlineIcon />
      </ErrorIconWrapper>
      <Typography variant="h4" gutterBottom>
        {errorMessage}
      </Typography>
    </Wrapper>
  );
};

export default ErrorDisplay;
