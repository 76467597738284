import { CSSProperties, useEffect, useRef } from "react";

import { WidgetDetails, Response, BotDetails } from "../types/common-types";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ReactMarkdown from "react-markdown";

interface HeroProps {
  responses: Response[];
  currentStream: string;
  botDetails: BotDetails | null;
  isLoading: boolean;
}

export default function Hero(props: HeroProps) {
  const { botDetails, responses, currentStream, isLoading } = props;
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [responses, currentStream, isLoading]);

  const styles = {
    botImageStyles: {
      borderRadius: '50%',
      height: "30px",
    },
    heroBoxStyles: {
      width: { lg: "67%", md: "80%", xs: "90%" },
      height: "calc(100% - 70px)",
      margin: "auto",
    },
    heroContainerStyles: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      justifyContent: "center",
      pt: { xs: 14, sm: 20 },
      pb: { xs: 8, sm: 12 },
    },
    chatContainerStyles: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      pt: { xs: 14, sm: 20 },
      pb: { xs: 8, sm: 5, md: 3 },
      paddingLeft:"0px !important",
      overflowY: "auto",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    heroHeadingStyles: {
      display: "flex",
      flexDirection: { xs: "column", md: "row" },
      alignSelf: "center",
      textAlign: "center",
      fontSize: "clamp(3.5rem, 10vw, 4rem)",
      color: "text.secondary",
    },
    heroSubheadingStyles: {
      alignSelf: "center",
      width: { sm: "100%", md: "80%" },
    },
    streamStyles: (theme: any) => ({
      ml: "none",
      maxWidth: "90%",
      width: "max-content",
      // backgroundColor:
      //   theme.palette.mode === "light"
      //     ? "rgba(6, 146, 226, 0.6)"
      //     : "text.primary",
      textAlign: "none",
      mb: 2,
      p: 3,
      borderRadius: "20px",
      color: "text.secondary",
      whiteSpace: "pre-wrap",
      overflowX: "auto",
    }),
    messageStyles: (isUser: boolean, theme: any) => ({
      ml: isUser ? "auto" : "none",
      maxWidth: "90%",
      width: "max-content",
      // backgroundColor: isUser
      //   ? "background.paper" : "transparent",
        // : theme.palette.mode === "light"
        // ? "rgba(6, 146, 226, 0.6)"
        // : "text.primary",
        backgroundColor: isUser ? theme.palette.mode === "light" ? "rgba(6, 146, 226, 0.6)" : "text.primary" : "transparent",
      textAlign: isUser ? "right" : "none",
      mb: 2,
      px: 2,
      py: isUser ? 1 : 0,
      borderRadius: isUser
        ? "20px 20px 20px 20px" 
        : "20px 20px 20px 20px", 
      color: "text.secondary",
      whiteSpace: "pre-wrap",
      overflowX: "auto",
    }),
    codeBlockStyles: {
      overflowX: "auto",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
      color: "text.secondary", 
      fontFamily: "monospace",
      fontSize: "14px",
    } as CSSProperties,
  };

  return (
    <Box id="hero" sx={styles.heroBoxStyles}>
      {responses.length === 0 && (
        <Container sx={styles.heroContainerStyles}>
          <Typography variant="h1" sx={styles.heroHeadingStyles}>
            <Box color="text.primary">
              {botDetails?.botWelcomeHeading ? (
                botDetails?.botWelcomeHeading
              ) : (
                <>Welcome!</>
              )}
            </Box>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={styles.heroSubheadingStyles}
          >
            {botDetails?.botWelcomeSubheading ? (
              botDetails?.botWelcomeSubheading
            ) : (
              <>How can I assist you today?</>
            )}
          </Typography>
        </Container>
      )}
      <Container sx={styles.chatContainerStyles} ref={chatContainerRef}>
  {responses.map((response: Response, index: number) => (
    <Box key={index} display="flex" flexDirection={response.isUser ? "row-reverse" : "row"} alignItems="flex-start" mb={2}>
      {!response.isUser && (
        <Box
          sx={styles.botImageStyles}
          component={"img"}
          alt="Bot Icon"
          src={botDetails?.botImage ? botDetails?.botImage : "/static/robot.png"}
          mt={"13px"} 
        />
      )}
      <Typography sx={(theme) => styles.messageStyles(response.isUser, theme)}>
        {response.isUser ? (
          response.message
        ) : (
          <ReactMarkdown
            components={{
              code({ node, className, children, ...props }) {
                return (
                  <code style={styles.codeBlockStyles} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {response.message}
          </ReactMarkdown>
        )}
      </Typography>
    </Box>
  ))}
  {currentStream && (
    <Box display="flex" flexDirection="row" alignItems="flex-start">
      <Box
          sx={styles.botImageStyles}
          component={"img"}
          alt="Bot Icon"
          src={botDetails?.botImage ? botDetails?.botImage : "/static/robot.png"}
          mt={"34px"} 
        />
      <Typography sx={styles.streamStyles}>
        <ReactMarkdown
          components={{
            code({ node, className, children, ...props }) {
              return (
                <code style={styles.codeBlockStyles} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {currentStream}
        </ReactMarkdown>
      </Typography>
    </Box>
  )}
</Container>


      ;
    </Box>
  );
}
