import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

function AppAppBar(props: any) {
  const {botDetails, theme} = props
  const styles = {
    appBarStyles: {
      boxShadow: 0,
      bgcolor: 'background.default',
      backgroundImage: 'none',
      py: 2
    },
    toolbarStyles: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexShrink: 0,
      borderRadius: '999px',
      bgcolor: 'background.paper',
      backdropFilter: 'blur(24px)',
      maxHeight: 40,
      border: '1px solid',
      borderColor: 'divider',
    },
    logoBoxStyles: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      ml: '3px',
      px: 0,
    },
    logoStyles: {
      width: '70px',
      height: 'auto',
      cursor: 'pointer',
    },
    botImageStyles: {
      backgroundColor: 'background.paper',
      padding: 1,
      borderRadius: '50%',
      margin: 'auto',
      height: 50,
    }
  }
  return (
    <div>
      <AppBar
        position="fixed"
        sx={styles.appBarStyles}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={styles.toolbarStyles}
          >
            <Box
              sx={styles.logoBoxStyles}
            >
              <img
                src={ botDetails.logo ? botDetails.logo : theme.palette.mode === 'light' ? '/static/Apex_Logo_2022_large.png' : '/static/Apex_Logo_2022_Large2.png'}
                style={styles.logoStyles}
                alt="logo of sitemark"
              />
            </Box>
            <Box
              sx={styles.botImageStyles}
              component={'img'}
              alt="Bot Icon"
              src={ botDetails.botImage ? botDetails.botImage :"/static/robot.png"}
            />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;